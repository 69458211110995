<template>
  <div>
    <v-container>
      <p>Fonctionnalité bientôt disponible :)</p>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "coming-soon",
  // mounted: async function () {
  //   await this.$store.dispatch("backBtn", true);
  // }
};
</script>
